type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const SocialPages = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_55_50628)">
				<path d="M2.75 8.25H22.25" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				<path
					d="M5.09375 11.2031H10.7188"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.09375 14.0156H7.4375"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.5625 14.0156H19.9063"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.09375 16.8281H7.4375"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M17.5625 16.8281H19.9063"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.2812 11.2031H19.9063"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.25 20.25H2.75V3.75H22.25V20.25H17.75"
					stroke={color}
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.5 18.4217C13.1731 18.4217 13.7188 17.876 13.7188 17.2029C13.7188 16.5298 13.1731 15.9842 12.5 15.9842C11.8269 15.9842 11.2812 16.5298 11.2812 17.2029C11.2812 17.876 11.8269 18.4217 12.5 18.4217Z"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.7188 15.9842V17.5076C13.7188 17.75 13.8151 17.9825 13.9865 18.154C14.1579 18.3254 14.3904 18.4217 14.6328 18.4217C14.8752 18.4217 15.1077 18.3254 15.2792 18.154C15.4506 17.9825 15.5469 17.75 15.5469 17.5076V17.2029C15.5468 16.5153 15.3142 15.8478 14.8867 15.3092C14.4592 14.7705 13.8621 14.3923 13.1924 14.236C12.5228 14.0797 11.8199 14.1546 11.1982 14.4484C10.5764 14.7422 10.0724 15.2377 9.76795 15.8543C9.46352 16.4709 9.37664 17.1724 9.52143 17.8446C9.66622 18.5169 10.0342 19.1204 10.5654 19.557C11.0967 19.9937 11.76 20.2377 12.4476 20.2496C13.1352 20.2614 13.8065 20.0403 14.3525 19.6222"
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_55_50628">
					<rect width="24" height="24" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default SocialPages;
