type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const SocialGroups = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
			<path d="M10 3H3V10H10V3Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M21 3H14V10H21V3Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M21 14H14V21H21V14Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M10 14H3V21H10V14Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};
export default SocialGroups;
