type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Book = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
			<path
				d="M19.8978 15.9998H7.89778C6.96781 15.9998 6.50282 15.9998 6.12132 16.102C5.08604 16.3794 4.2774 17.1881 4 18.2233"
				stroke={color}
			/>
			<path d="M7 16V2.5" stroke={color} strokeLinecap="round" />
			<path
				d="M10 21.9999C7.17157 21.9999 5.75736 21.9999 4.87868 21.1212C4 20.2425 4 18.8283 4 15.9999V7.99994C4 5.17151 4 3.7573 4.87868 2.87862C5.75736 1.99994 7.17157 1.99994 10 1.99994H14C16.8284 1.99994 18.2426 1.99994 19.1213 2.87862C20 3.7573 20 5.17151 20 7.99994M14 21.9999C16.8284 21.9999 18.2426 21.9999 19.1213 21.1212C20 20.2425 20 18.8283 20 15.9999V11.9999"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
};
export default Book;
